import React, { memo, Suspense } from 'react'
import Box from '../../components/Box'
import styles from './index.module.css'
import { AuthProvider } from 'react-oauth2-code-pkce'
import { authConfig } from '@/components/authconfig'
import NavigationBar from '@/components/NavigationBar'
import { UserInfo } from '@/components/UserInfo'

interface Props {}

const Index: React.FC<Props> = memo(() => {
  return (
    <>
      <AuthProvider authConfig={authConfig}>
        <NavigationBar />
        <div className="grid justify-items-center p-8 pb-20 gap-16 sm:p-20">
          <main className="flex flex-col gap-8 row-start-1 items-center sm:items-start">
            <div className="flex gap-4 flex-col">
              <Box>
                <h1 className={styles.h1}>👋 @ dreimann.net</h1>
              </Box>
              <UserInfo />
            </div>
          </main>
        </div>
      </AuthProvider>
    </>
  )
})
Index.displayName = 'Index'

export default Index
