import { useState, useContext, useEffect } from 'react'
import { IAuthContext, AuthContext } from 'react-oauth2-code-pkce'

export const UserInfo: React.FC = () => {
  const [data, setData] = useState<UserData>({
    email_verified: '',
    name: '',
    phone_number_verified: '',
    phone_number: '',
    email: '',
    username: '',
  })

  const [isLoading, setIsLoading] = useState(false)
  const { tokenData, token, logIn, logOut, idToken, error }: IAuthContext =
    useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const fetchedData = await fetchDataGet(
        'https://auth.dreimann.net/oauth2/userInfo',
        token,
      )
      setData(fetchedData)
      setIsLoading(false)
    }
    fetchData()
  }, [token])

  if (isLoading && error) {
    return (
      <>
        <div style={{ color: 'red' }}>
          An error occurred during authentication: {error}
        </div>
        <button onClick={() => logOut()}>Log out</button>
      </>
    )
  }

  return (
    <>
      {token && isLoading && <p>Data is being loaded..</p>}
      {token && !isLoading && (
        <p>
          <UserTable data={data} />
        </p>
      )}
    </>
  )
}

export const fetchDataGet = async (url: string, token: string) => {
  const bearerToken = 'Bearer ' + token
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: bearerToken,
      'Content-Type': 'application/json',
    },
  })
  return response.json()
}

import React from 'react'
import 'tailwindcss/tailwind.css'

interface UserData {
  email_verified: string
  name: string
  phone_number_verified: string
  phone_number: string
  email: string
  username: string
}

const UserTable: React.FC<{ data: UserData }> = ({ data }) => {
  return (
    <div className="p-6 bg-white shadow-md rounded border border-gray-200">
      <div className="mb-4">
        <div className="text-gray-600 font-bold uppercase text-sm">Name</div>
        <div className="text-gray-800 text-lg">{data.name}</div>
      </div>
      <div className="mb-4">
        <div className="text-gray-600 font-bold uppercase text-sm">
          Username
        </div>
        <div className="text-gray-800 text-lg">{data.username}</div>
      </div>
      <div className="mb-4 flex items-center">
        <div className="flex-grow">
          <div className="text-gray-600 font-bold uppercase text-sm">Email</div>
          <div className="text-gray-800 text-lg">{data.email}</div>
        </div>
        <span
          className={`inline-block text-xs font-bold px-2 py-1 ml-4 rounded self-center ${data.email_verified === 'true' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}
        >
          {data.email_verified === 'true' ? 'Verified' : 'Not Verified'}
        </span>
      </div>
      <div className="mb-4 flex items-center">
        <div className="flex-grow">
          <div className="text-gray-600 font-bold uppercase text-sm">
            Phone Number
          </div>
          <div className="text-gray-800 text-lg">{data.phone_number}</div>
        </div>
        <span
          className={`inline-block text-xs font-bold px-2 py-1 ml-4 rounded self-center ${data.phone_number_verified === 'true' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}
        >
          {data.phone_number_verified === 'true' ? 'Verified' : 'Not Verified'}
        </span>
      </div>
      <div className="mt-6 text-sm text-gray-500 border-t pt-4">
        Incorrect? Contact Philipp{' '}
        <a href="mailto:philipp@dreimann.net?subject=Please update my user profile">
          📧
        </a>
      </div>
    </div>
  )
}
