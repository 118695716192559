import React, { useState, useContext, useEffect } from 'react'
import { AuthContext, AuthProvider, IAuthContext } from 'react-oauth2-code-pkce'
import { useNavigate } from 'react-router-dom'

interface User {
  username: string
  avatarUrl: string
}

const NavigationBar: React.FC = () => {
  const [user, setUser] = useState<User | null>(null)
  const { tokenData, token, logIn, logOut, idToken, error }: IAuthContext =
    useContext(AuthContext)
  const navigate = useNavigate()

  const login = () => {
    logIn()
    navigate('/')
  }

  const logout = () => {
    logOut()
    navigate('/')
    window.location.reload() // Force re-render of UserInfo component
  }

  useEffect(() => {
    if (token) {
      setUser({
        username: tokenData?.username || 'Unknown',
        avatarUrl:
          'https://api.dicebear.com/9.x/pixel-art/svg?seed=' +
            tokenData?.username || 'Unknown',
      })
    } else {
      setUser(null)
    }
  }, [token, tokenData])

  return (
    <>
      <nav className="text-white p-4 flex justify-between items-center bg-background">
        <button
          className="text-xl font-bold hover:bg-grey-900"
          onClick={() => (window.location.href = window.location.origin)}
        >
          dreimann.net
        </button>
        <div>
          {user ? (
            <div className="relative flex items-center space-x-4">
              <img
                src={user.avatarUrl}
                alt="Avatar"
                className="w-10 h-10 rounded-full"
              />
              <div className="absolute right-0 top-full mt-2 bg-white rounded-lg shadow-lg opacity-0 hover:opacity-100 transition-opacity duration-300">
                <div className="p-4 border-b">
                  <div className="text-gray-500 font-bold uppercase text-sm">
                    Username
                  </div>
                  <span className="block text-gray-700 text-sm sm:text-base">
                    {user.username}
                  </span>
                </div>
                <div className="p-4">
                  <button
                    onClick={logout}
                    className="text-gray-600 text-sm sm:text-base  flex item-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-logout"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                      <path d="M9 12h12l-3 -3" />
                      <path d="M18 15l3 -3" />
                    </svg>
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <button
              onClick={login}
              className="rounded-full border border-solid border-transparent transition-colors flex items-center justify-center bg-foreground text-background gap-2 hover:bg-[#383838] dark:hover:bg-[#ccc] text-sm sm:text-base h-10 sm:h-12 px-4 sm:px-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-login-2"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2" />
                <path d="M3 12h13l-3 -3" />
                <path d="M13 15l3 -3" />
              </svg>
              Sign In
            </button>
          )}
        </div>
      </nav>
    </>
  )
}

export default NavigationBar
