import { TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce'

const getRedirectUri = () => {
  // Check if we're in development mode
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000'
  }

  // In production, use the actual domain
  const hostname = window.location.hostname
  if (hostname === 'www.dreimann.net' || hostname === 'dreimann.net') {
    return `https://${hostname}`
  }

  // Fallback to the main domain if something unexpected happens
  return 'https://dreimann.net'
}

export const authConfig: TAuthConfig = {
  clientId: 'vc8ep9k1ktne7qidhrt9o5gp9',
  authorizationEndpoint: 'https://auth.dreimann.net/oauth2/authorize',
  tokenEndpoint: 'https://auth.dreimann.net/oauth2/token',
  redirectUri: getRedirectUri(), // This is the URL where the user will be redirected after the login
  scope: 'phone profile openid email',
  autoLogin: false,
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
    event.logIn(undefined, undefined, 'popup'),
}
